import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import Translate from '../components/Translate';
import Layout from '../components/layout';
import backgroundImage from '../data/images/support/support-hero.jpg';
import responsiveBackgroundImage from '../data/images/support/support-hero-mobile.jpg';
import computerIcon from '../data/images/SVG-files/icon-computer-grey.svg';
import phoneIcon from '../data/images/SVG-files/icon-phone-grey.svg';
import chatIcon from '../data/images/SVG-files/icon-chat.svg';
import statusIcon from '../data/images/SVG-files/icon-status.svg';
import i18next from '../config/i18next';

const styles = {
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)'
    },
    supportSection: {
        background: '#ffffff',
        height: '40vh',
        minHeight: '400px',
        display: 'flex',
        justifyContent: 'center',
        width: '60%',
        marginTop: '20vh',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '2px',
        textAlign: 'center'
    },
    supportHeader: {
        fontSize: '2em',
        lineHeight: '30px',
        fontWeight: '500',
        marginBottom: '50px'
    },
    supportContent: {
        display: 'inline-flex',
        justifyContent: 'space-around',
        fontSize: '1.2em',
        fontWeight: '300',
        color: '#333333',
        width: '100%'
    },
    imageContainer: {
        width: '130px',
        height: '130px',
        marginBottom: '50px'
    },
    separator: {
        borderLeft: '1px solid #eee',
        margin: '0 10px 0 10px',
        height: '200px'
    },
    icon: {
        width: '80px',
        height: '80px'
    },
    '@media only screen and (max-width: 767px)': {
        separator: {
            display: 'none'
        },
        imageContainer: {
            flexBasis: '35%',
            alignSelf: 'baseline',
            '& p': {
                margin: 0
            }
        },
        supportSection: {
            height: '75vh',
            display: 'flex',
            padding: '10px',
            width: '80%',
            marginTop: '8vh'
        },
        supportHeader: {
            fontSize: '1.5em',
            fontWeight: '400'
        },
        supportContent: {
            display: 'flex',
            flexWrap: 'wrap'
        }
    }
};

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ''
        };
    }

    componentDidMount() {
        this.loadTitleTranslation();
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('main_container', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`main_container:help.title`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const title = this.state.title;

        return (
            <Layout responsiveBackgroundUrl={responsiveBackgroundImage} backgroundUrl={backgroundImage}>
                <div className={classes.landingPage}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta
                            name="description"
                            content="Whether you’re troubleshooting or learning to use new features on Garmin Connect, we have the help you need."
                        />
                        <meta name="title" content="Garmin Connect | Help" />
                        <meta name="keywords" content="Find Help, Garmin Connect" />
                        <meta property="og:title" content="Garmin Connect | Help" />
                        <meta property="og:url" content={`https://connect.garmin.com/help`} />
                        <meta
                            property="og:description"
                            content="Whether you’re troubleshooting or learning to use new features on Garmin Connect, we have the help you need."
                        />
                        <meta property="og:image" content="http://fenix3.garmin.com/ui/images/mountain-running.jpg" />
                        <meta name="twitter:title" content="Garmin Connect | Help" />
                    </Helmet>
                    <div className={classes.supportSection}>
                        <div className={classes.supportHeader}>
                            {<Translate ns="prelogin_pages" content="gc_support" />}
                        </div>
                        <div className={classes.supportContent}>
                            <div className={classes.imageContainer}>
                                <a href="https://support.garmin.com/sas/gcwebhelp/?productId=73207">
                                    <img src={computerIcon} className={classes.icons} alt="" />
                                    <p>Garmin Connect</p>
                                </a>
                            </div>

                            <div className={classes.separator} />

                            <div className={classes.imageContainer}>
                                <a href="https://support.garmin.com/sas/gcwebhelp/?productId=125677">
                                    <img src={phoneIcon} className={classes.icons} alt="" />
                                    <p>{<Translate ns="prelogin_pages" content="garmin_connect_mobile" />}</p>
                                </a>
                            </div>

                            <div className={classes.separator} />

                            <div className={classes.imageContainer}>
                                <a href="https://forums.garmin.com">
                                    <img src={chatIcon} className={classes.icons} alt="" />
                                    <p>{<Translate ns="prelogin_pages" content="customer_forum" />}</p>
                                </a>
                            </div>

                            <div className={classes.separator} />

                            <div className={classes.imageContainer}>
                                <Link to="/status/">
                                    <img src={statusIcon} className={classes.icons} alt="" />
                                    <p>{<Translate ns="prelogin_pages" content="system_status" />}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Support);
